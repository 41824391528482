export default {
  colors: {
    blue: '#0075C9',
    red: '#ED1C24',
    white: '#FFFFFF',
    grayLight: '#F2F2F2',
    grayMedium: '#686868',
    grayDark: '#333333',
  },
  fonts: {
    primary: 'Arial, sans-serif',
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};
